import React from 'react'
import { BsGlobe } from 'react-icons/bs'
import { CiMail } from 'react-icons/ci'
import { FiPhone } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import { onDeleteLeads } from '../../Redux/Actions/AuthActions'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
const LeadCard = ({ item, fetchLeadData }) => {
    const dispatch = useDispatch()

    const handleDeleteById = async () => {
        let data = {
            id: item.id
        };

        const result = await Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "You will not be able to recover this data!",
            showCancelButton: true,
            confirmButtonColor: "#d03e3e",
            cancelButtonColor: "#6c757d",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        });

        if (result.isConfirmed) {
            try {
                await dispatch(onDeleteLeads(data, fetchLeadData, false));
            } catch (error) {
                console.error('Error deleting items:', error);
            }
        }
    };

    return (
        // <tbody>
        <tr>
            {/* <td>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="checked" />
                    </div>
                </td> */}
            <td className=''>
                {item.businessName}</td>
            <td>
                <div class="detailTd lead_data">
                    <div class="lead_content">
                        <small> <FiPhone /></small>
                        <small> {item.phoneNumber ? item.phoneNumber : "N/A"}</small>
                    </div>
                    <div class="lead_content">
                        <small><CiMail /></small>
                        <small> {item.emails ? item.emails : "N/A"}</small>
                    </div>
                    <div class="lead_content">
                        <span style={{ borderBottom: "none" }}>
                            <small> <BsGlobe /></small>
                            <small>
                                <span >{item.website ? item.website : "N/A"}</span>
                            </small>
                        </span>
                    </div>
                </div>
            </td>

            {/* <td>{item.status}</td> */}
            <td>
                <span>
                    <a href={item.mapUrl}>View</a>
                </span>
            </td>
            <td>{item.userRatingsTotal}</td>
            <td>
                <MdDelete fontSize={20} onClick={handleDeleteById} style={{ cursor: "pointer" }} />
            </td>
        </tr>

        // </tbody>
    )
}

export default LeadCard