import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { onEditFolder } from '../../Redux/Actions/AuthActions'
import { FaSpinner } from 'react-icons/fa'

const EditWebsiteModal = ({ handleClose, curElem, show, leads, setLeads }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [edit, setEdit] = useState({
        name: ""
    })
    const handleSave = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = {
            id: curElem.sourceId,
            name: edit.name
        }
        dispatch(onEditFolder(data, handleClose, setLoader, leads, setLeads));
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        setEdit({ ...edit, [name]: value })
    }

    useEffect(() => {
        setEdit({ ...edit, name: curElem.folderName })
    }, [curElem])
    return (
        <>
            <Modal show={show} onHide={handleClose} className='results_modal'>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSave}>
                        <Modal.Title className='mt-2 mb-2'>Edit Website Name</Modal.Title>

                        <div class="mb-3">
                            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="" value={edit.name} name='name' onChange={handleChange} required />
                        </div>
                        <div className="result_btnWrap">
                            <Button variant="secondary" className='black_btn' onClick={handleClose} style={{ color: "#000" }}>
                                Close
                            </Button>

                            <Button variant="primary" className='blue_btn' type='submit' style={{ color: "#0d6efd" }}>
                                <span> Save Changes {loader ? <FaSpinner className="spin ms-1" /> : null}</span>
                            </Button>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
        </>)
}

export default EditWebsiteModal