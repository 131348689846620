import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { forgotPassword } from '../../Redux/Actions/AuthActions'
import { useDispatch } from 'react-redux'
import { FaSpinner } from 'react-icons/fa'
function ForgotPassword() {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [email, setEmail] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      email: email
    }
    setLoader(true)
    dispatch(forgotPassword(data, setLoader))
  }
  return (
    <>
      <div className="login-header">
        <div className="container">
          <div className="row">
            <div className="logo"><img src={require("../../images/logo.svg").default} alt="" /></div>
          </div>
        </div>
      </div>
      <div className='login-container'>
        <div className='container'>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5">
              <div className="login-left">
                <h3>Welcome to AI Clients</h3>
                <p className='pt-3'>Elevate your digital presence with our AI-driven tool for effortless reputation management. Collect and analyze key insights from various sources—business details, visual content, customer feedback, and ratings. Start your journey towards a powerful online identity now.
                </p>
                <h3 className='pt-4' style={{ fontSize: "20px" }}>..it's Super Easy-to-use</h3>

              </div>
            </div>
            <div className="col-lg-6">
              <form onSubmit={handleSubmit}>
                <div className="login-right">
                  <div className='login-card'>
                    <h5 className='mb-4'>Enter Your Email Address</h5>
                    <div className="input-wrap">
                      <label className='label' htmlFor="">Email:</label>
                      <input className='input' type="email" placeholder='Enter Your Email' onChange={(e) => setEmail(e.target.value)} value={email} required />
                    </div>
                    <div className="input-wrap mt-4">
                      <button className='site-link lg full black mt-1'>Submit {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                    </div>
                    {/* <p className='text-center pt-4'>Don't have account? <Link className='txt-orange' to="/register">Register </Link></p> */}
                    <p className='text-center pt-2'>Remember Password? <Link className='txt-orange' to="/login">Login</Link></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword