import React from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai"
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useState } from "react";
import queryString from "query-string";
import { FaSpinner } from "react-icons/fa";
import { resetPassword } from "../../Redux/Actions/AuthActions";

const ResetPassword = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    let location = useLocation()

    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })
    const [type, setType] = useState({
        password: "password",
        confirm_password: "password"
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (loginInfo.password) {
            if (pattern.test(loginInfo.password)) {
                if ((loginInfo.password === loginInfo.confirm_password)) {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                    setLoader(true);
                    dispatch(resetPassword(loginInfo, setLoader, navigate));
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }

    const handleType = (val) => {
        if (val === "pass") {
            setType({
                ...type,
                password: "text"
            })

            setTimeout(() => {
                setType({
                    ...type,
                    password: "password"
                })
            }, 2000)
        } else {
            setType({
                ...type,
                confirm_password: "text"
            })
            setTimeout(() => {
                setType({
                    ...type,
                    confirm_password: "password"
                })
            }, 2000)
        }
    }


    return (
        <>
            <div className="login-header">
                <div className="container">
                    <div className="row">
                        <div className="logo"><img src={require("../../images/logo.svg").default} alt="" /></div>
                    </div>
                </div>
            </div>
            {/* <TitleBar title="Reset Password" /> */}
            <div className='login-container'>
                <div className='container'>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5">
                            <div className="login-left">
                                <h3>Welcome to AI Clients</h3>
                                <p className='pt-3'>Elevate your digital presence with our AI-driven tool for effortless reputation management. Collect and analyze key insights from various sources—business details, visual content, customer feedback, and ratings. Start your journey towards a powerful online identity now.
                                </p>
                                <h3 className='pt-4' style={{ fontSize: "20px" }}>..it's Super Easy-to-use</h3>

                            </div>
                        </div>
                        <div className="col-lg-6 mb-4">
                            <form onSubmit={handleSubmit}>
                                <div className="login-right">
                                    <div className='login-card'>
                                        <h5 className='mb-4'>Enter New Password</h5>
                                        <div className="input-wrap">
                                            <label htmlFor="">New Password</label>
                                            <div className="inp-outer">
                                                <input
                                                    className="input"
                                                    type={type.password}
                                                    placeholder='***********'
                                                    name="password"
                                                    value={loginInfo.password}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span className="inp-icon" style={{ cursor: "pointer" }} onClick={() => handleType("pass")}><AiOutlineEyeInvisible /></span>
                                            </div>
                                        </div>
                                        <div className="input-wrap mt-4">
                                            <label htmlFor="">Confirm Password</label>
                                            <div className="inp-outer">
                                                <input
                                                    className="input"
                                                    type={type.confirm_password}
                                                    placeholder='***********'
                                                    name="confirm_password"
                                                    value={loginInfo.confirm_password}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span className="inp-icon" style={{ cursor: "pointer" }} onClick={() => handleType("confirm-pass")}><AiOutlineEyeInvisible /></span>
                                            </div>
                                        </div>
                                        <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                                        <div className="input-wrap mt-4">
                                            <button className="site-link lg full black mt-1" type="submit"><span>Reset Password {loader ? <FaSpinner className="spin" /> : ""}</span></button>
                                        </div>
                                        <p class="text-center pt-2">Sign in? <Link class="txt-orange" to="/">Click Here</Link></p>
                                    </div>
                                </div>
                            </form>
                            {/* <div className="log-option text-center">Sign in? <Link to="/">Click Here</Link></div> */}

                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="login-wrap">

                <div className="login-left">
                    <div className="welcome-txt">
                        <h2><span>Welcome To </span>AI Client</h2>
                        <p>Revolutionise your online reputation management with our AI-driven tool. Automate, enhance, and influence your business's online presence effectively. Begin your journey to a transformative digital reputation now!</p>
                        <h3>..it's Super Easy-to-use</h3>
                    </div>
                </div>

                <div className="login-right">
                    <div className="login-right-main">
                        <h2>Reset Password</h2>
                        <form className="login-form"<label htmlFor="">New Password</label>
                                <div className="inp-outer">
                                    <input
                                        className="inp"
                                        type={type.password}
                                        placeholder='***********'
                                        name="password"
                                        value={loginInfo.password}
                                        onChange={handleChange}
                                        required
                                    />
                                    <span className="inp-icon" style={{ cursor: "pointer" }} onClick={() => handleType("pass")}><AiOutlineEyeInvisible /></span>
                                </div> onSubmit={handleSubmit}>

                            <div className="input-wrap">
                                
                            </div>
                            <div className="input-wrap">
                                <label htmlFor="">Confirm Password</label>
                                <div className="inp-outer">
                                    <input
                                        className="inp"
                                        type={type.confirm_password}
                                        placeholder='***********'
                                        name="confirm_password"
                                        value={loginInfo.confirm_password}
                                        onChange={handleChange}
                                        required
                                    />
                                    <span className="inp-icon" style={{ cursor: "pointer" }} onClick={() => handleType("confirm-pass")}><AiOutlineEyeInvisible /></span>
                                </div>
                            </div>
                            <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                            <div className="input-wrap">
                                <button className="theme-btn full" type="submit"><span>Reset Password {loader ? <FaSpinner className="spin" /> : ""}</span></button>
                            </div>
                        </form>

                        <div className="log-option text-center">Sign in? <Link to="/">Click Here</Link></div>
                    </div>



                </div>


            </div> */}


        </>
    )
}

export default ResetPassword;