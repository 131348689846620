import React, { useEffect } from 'react'

const WebsiteEmailCard = ({ websiteScrap, sId }) => {

    return (
        <>
            {websiteScrap.map((item) => {
                return <div className="row">
                    <div className="col-12">
                        <h3 className='my-3 ms-1'>Website Search Data</h3>
                        <div className="website_data_wrap">



                            <div className="row">
                                <div className="col-xl-6 col-12 mb-4">
                                    <div className="inner_data_wrap">
                                        <div className="row">
                                            <div className="col-md-3 col-sm-4 col-12 data_end">
                                                <h6>Email:</h6>
                                            </div>
                                            <div className="col-md-9 col-sm-8 col-12">
                                                <div className='response_data'>
                                                    <div className="row">
                                                        {item.emails?.length > 0 ?
                                                            item.emails?.map((i, index) => {

                                                                return (
                                                                    <div className="col-md-6 col-12 data_center ">
                                                                        <span>{i}</span>
                                                                    </div>
                                                                )
                                                            }) : "N/A"}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6 mb-4">
                                    <div className="inner_data_wrap">
                                        <div className="row">
                                            <div className="col-md-3 col-sm-4 col-12 data_end">
                                                <h6>Contact:</h6>
                                            </div>
                                            <div className="col-md-9 col-sm-8 col-12">
                                                <div className='response_data'>
                                                    <div className="row">
                                                        {item.contactNumber?.length > 0 ?

                                                            item.contactNumber?.map((i, index) => {
                                                                return (
                                                                    <div className="col-md-6 col-12 data_center ">
                                                                        <span>{i}</span>
                                                                    </div>
                                                                )
                                                            }) : "N/A"}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 mb-4">
                                    <div className="inner_data_wrap">
                                        <div className="row">
                                            <div className="col-12 ">
                                                <h5 style={{ padding: "0 0 24px 36px" }}>All Script </h5>
                                            </div>

                                            <div className="col-12">
                                                <ul>
                                                    <li className='mb-3'>
                                                        <h6>GoogleAds Pixel :-</h6><span>{item.additionalInfo.allScript.googleAdsPixel?.toString()}</span>
                                                    </li>
                                                    <li className='mb-3'>
                                                        <h6>Google Analytics :-</h6><span>{item.additionalInfo.allScript.googleAdsPixel?.toString()}</span>
                                                    </li>
                                                    <li className='mb-3'>
                                                        <h6>Facebook Analytics Pixel :-</h6><span>{item.additionalInfo.allScript.facebookAnalyticsPixel?.toString()}</span>
                                                    </li>
                                                    <li className='mb-3'>
                                                        <h6>Facebook Analytics Pixel :-</h6><span>{item.additionalInfo.allScript.facebookAnalyticsPixel?.toString()}</span>
                                                    </li>
                                                    <li className='mb-3'>
                                                        <h6>Google Tag Manager :-</h6><span>{item.additionalInfo.allScript.googleTagManager?.toString()}</span>
                                                    </li>
                                                    <li className='mb-3'>
                                                        <h6>Facebook Pixel :-</h6><span>{item.additionalInfo.allScript.facebookPixel?.toString()}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mb-4">
                                    <div className="inner_data_wrap">
                                        <div className="row">
                                            <div className="col-12 ">
                                                <h5 style={{ padding: "0 0 24px 36px" }}>Extension: </h5>
                                            </div>

                                            <div className="col-12">
                                                <ul>
                                                    <li className='mb-3'>
                                                        <h6>Contact Call To Action :-</h6><span>{item.additionalInfo.extension.contactCallToAction?.toString()}</span>
                                                    </li>
                                                    <li className='mb-3'>
                                                        <h6>Webmaster Tools :-</h6><span>{item.additionalInfo.extension.webmasterTools?.toString()}</span>
                                                    </li>
                                                </ul>
                                            </div>

                                            {/* <div className="col-12 col-md-6 mb-md-0 mb-4">
                  <div className="row">
                    <div className="col-xl-6 col-6 data_end">
                      <h6>Contact Call To Action :-</h6>
                    </div>

                    <div className="col-xl-6 col-6">
                      <div className='response_data'>
                        <div className="row">
                          <div className="col-12 ">
                            <span>{item.additionalInfo.extension.contactCallToAction?.toString()}</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-md-0 mb-4">
                  <div className="row">
                    <div className="col-xl-6 col-6 data_end">
                      <h6>Webmaster Tools :-</h6>
                    </div>

                    <div className="col-xl-6 col-6">
                      <div className='response_data'>
                        <div className="row">
                          <div className="col-12 ">
                            <span>{item.additionalInfo.extension.webmasterTools?.toString()}</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div> */}


                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-12 mb-4">
                                    <div className="inner_data_wrap">
                                        <div className="row">
                                            <div className="col-12 ">
                                                <h5 style={{ padding: "0 0 24px 36px" }}>Social Links </h5>
                                            </div>
                                            {
                                                // console.log(Object.keys(item?.additionalInfo.socialLinks))
                                                Object.keys(item?.additionalInfo.socialLinks).map(keys => {
                                                    return <div className="col-xl-6 col-12 mb-md-0 mb-4">
                                                        <div className="row">
                                                            <div className="col-xl-3 col-lg-2 col-sm-3 col-12 data_end">
                                                                <h6>{keys}:</h6>
                                                            </div>

                                                            <div className="col-xl-9 col-lg-10 col-sm-9 col-12">
                                                                <div className='response_data'>
                                                                    <div className="row">
                                                                        <div className="col-12 ">
                                                                            <a href={item.additionalInfo.socialLinks[keys]} target='_blank'>{item.additionalInfo.socialLinks[keys] ? item.additionalInfo.socialLinks[keys] : "N/A"}</a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                })
                                            }

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            })}
        </>
    )
}

export default WebsiteEmailCard