import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Actions/AuthActions";
import { FaSpinner } from "react-icons/fa";
import { PiUserCircleDuotone } from "react-icons/pi";
import { Dropdown } from "react-bootstrap";
import { FiLock, FiLogOut, FiUser } from "react-icons/fi";
import { IoMenuSharp } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";

function Header() {
  const [active, SetActive] = useState(false);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  // const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    // setLoader(true);
    dispatch(logoutUser(navigate));
  };

  const isResultsActive = () => {
    return location.pathname === "/results" || location.pathname === "/website-result";
  };
  return (
    <>
      <header className="site-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="site-header-in">
                <div className="logo"><Link to="/"><img src={require("../../images/logo.svg").default} alt="" /></Link></div>
                <nav className={`site-nav ${active ? "active" : ""}`}>
                  <span className="nav-close-btn" onClick={() => { SetActive(false) }}><IoCloseSharp /></span>
                  <ul>
                    <li><NavLink to="/dashboard" className={location.pathname === "/dashboard" || location.pathname === "/" ? "inner-active" : ""}>Dashboard</NavLink></li>

                    <li><NavLink to="/sources" className={location.pathname === "/sources" || location.pathname === "/google-map" || location.pathname === "/website-email" ? "inner-active" : ""}>Sources</NavLink></li>

                    {/* <li>
                      <NavLink to="/results" className={location.pathname === "/results" ? "inner-active" : ""}>Results</NavLink>



                    </li> */}
                    <li className={isResultsActive() ? "inner-active" : ""}>
                      <div className="result-menu">
                        <Dropdown>
                          <Dropdown.Toggle variant="successN" className="btn_toggle">
                            Results
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <NavLink to="/results" activeClassName="inner-active">Google Results</NavLink>
                            <NavLink to="/website-result" activeClassName="inner-active">Website Results</NavLink>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </li>
                    <li>
                      <div className="profile-menu">
                        <Dropdown>
                          <Dropdown.Toggle variant="successN" className="drop-btn">
                            {/* <img alt="" src={auth.user.profile} /> */}
                            <PiUserCircleDuotone fontSize={35} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <NavLink to="/my-account"><FiUser /> My Account</NavLink>
                            <NavLink to="/privacy-setting"><FiLock /> Privacy Setting</NavLink>
                            <a className="cursor-pointer" onClick={handleLogout}><FiLogOut /> Logout</a>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </li>
                  </ul>
                </nav>
                <span className="hamburger" onClick={() => { SetActive(true) }}><IoMenuSharp /></span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
