import React from "react";
import logo from '../../images/aisuites.png'
function Footer() {

    return (
        <>
            <footer className="site-footer ">
                <div className="container-fluid">
                    <div className="footer-in">
                        <div className="row align-items-center">
                            <div className="col-lg-3 p-0">

                                <div className="footer-right">
                                    <ul>
                                        <li><a href="https://aiclients.io/terms.html" target="_blank">Terms & Conditions | </a></li>
                                        <li><a href="https://aiclients.io/privacy/privacy.html" target="_blank">Privacy | </a></li>
                                        <li><a href="http://support.vineasx.com/" target="_blank">Support</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-7 ">
                                <div className="footer-left">
                                    <p>© {(new Date().getFullYear())}, All Rights Reserved to <a href="https://vineasx.com/" target="_blank" rel="noreferrer">VineaSX Solutions LLC.</a> Developed and Maintained by <a href="http://www.vega6.com/" target="_blank" rel="noreferrer">Vega6</a>
                                        <br /> You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</p>
                                </div>
                            </div>
                            <div className="col-lg-2 text-center text-lg-end">
                                <div className='footer-logo'><a href="https://aisuites.io/" target="_blank">
                                    <img src={logo} alt="" /></a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </footer >
        </>
    )
}

export default Footer;