import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.scss';
import { ThemeProvider } from "./Components/Theme-Context/ThemeContext";
import Home from "./Components/Home/Home";
import Sources from "./Components/Sources/Sources";
import LinkedinProfile from "./Components/Sources/LinkedinProfile";
import LinkedinCompany from "./Components/Sources/LinkedinCompany";
import WebsiteEmail from "./Components/Sources/WebsiteEmail";
import MapSearch from "./Components/Sources/MapSearch";
import InstagramFollower from "./Components/Sources/InstagramFollower";
import Dashboard from "./Components/Dashboard/Dashboard";
import Pricing from "./Components/Pricing/Pricing";
import Login from "./Components/Auth/Login";
import { removeAlert } from "./Redux/Actions/AlertAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import MasterLogin from "./Components/Auth/MasterLogin";
import GoogleSearchData from "./Components/Sources/GoogleSearchData";
import ResultsCard from "./Components/Results/ResultsCard";
import GoogleCard from "./Components/Sources/GoogleCard";
import Alert from "./Components/Common/Alert";
import Header from "./Components/Common/Header";
import Footer from "./Components/Common/Footer";
import PrivateRoute from "./Components/Common/PrivateRoute";
import MyAccount from "./Profile/MyAccount";
import PrivacySetting from "./Profile/PrivacySetting";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import WebsiteResult from "./Components/WebsiteResult/WebsiteResult";


function App() {
  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])
  return (
    <ThemeProvider>
      <Alert />

      <div className="App">

        <Router>
          <Routes>
            <Route path="/login" element={< Login />} />
            <Route path="/forgot-password" element={< ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />

            <Route path="/master-login" element={< MasterLogin />} />
            <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/dashboard" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/sources" element={<PrivateRoute><Sources /></PrivateRoute>} />
            <Route path="/google-map" element={<PrivateRoute><GoogleSearchData /></PrivateRoute>} />
            <Route path="/linkedin-profile" element={<PrivateRoute><LinkedinProfile /></PrivateRoute>} />
            <Route path="/linkedin-company" element={<PrivateRoute><LinkedinCompany /></PrivateRoute>} />
            <Route path="/website-email" element={<PrivateRoute><WebsiteEmail /></PrivateRoute>} />
            <Route path="/map-search" element={<PrivateRoute>< MapSearch /></PrivateRoute >} />
            <Route path="/instagram-follower" element={<PrivateRoute>< InstagramFollower /></PrivateRoute >} />
            <Route exact path="/my-account" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
            {/* // <Route path="/dashboard" element={<Dashboard />} /> */}
            < Route path="/pricing" element={<PrivateRoute>< Pricing /></PrivateRoute >} />
            {/* < Route path="/register" element={<PrivateRoute>< Register /></PrivateRoute >} /> */}
            < Route path="/results" element={<PrivateRoute>< ResultsCard /></PrivateRoute >} />
            < Route path="/website-result" element={<PrivateRoute>< WebsiteResult /></PrivateRoute >} />

            < Route path="/privacy-setting" element={<PrivateRoute>< PrivacySetting /></PrivateRoute >} />


          </Routes >
        </Router >
      </div >

    </ThemeProvider >
  );
}

export default App;
