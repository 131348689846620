import React, { useState } from 'react'
import Layout from '../Layout'
import { useDispatch, useSelector } from 'react-redux'
import { FaSpinner } from 'react-icons/fa'
import { onChangeEmail, onDeleteAccount, onDisableAccount } from '../Redux/Actions/AuthActions'
import { Helmet } from 'react-helmet'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
const PrivacySetting = () => {
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const [email, setEmail] = useState("")
    const [loader, setLoader] = useState(false)

    const handleDelete = async () => {

        const result = await Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "You will not be able to recover this data!",
            showCancelButton: true,
            confirmButtonColor: "#d03e3e",
            cancelButtonColor: "#6c757d",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        });

        if (result.isConfirmed) {
            try {
                await dispatch(onDeleteAccount(setLoader));
            } catch (error) {
                console.error('Error deleting items:', error);
            }
        }
    };

    // const handleDownload = () => {
    //     dispatch(onDownloadData(setLoader))
    // }
    const handleDisable = () => {
        dispatch(onDisableAccount(setLoader))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            email: email
        }
        setLoader(true)
        dispatch(onChangeEmail(data, setLoader))
    }
    return (
        <>
            <Layout>
                <Helmet>
                    <title> AI Clients | Privacy</title>
                </Helmet>
                <div className="site-wrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy_wrap">
                                    <div className="profileBlock-wrap ">
                                        <h2 className="infoTitle">Change Email</h2>
                                        <div className="account-block mt-0 mb-2 py-5">

                                            <form className="" method="post" onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">

                                                        <div className="input-wrap">
                                                            <label htmlFor="">Current Email Address</label>
                                                            <div className="input-outer">
                                                                <input
                                                                    className="common-inp alt"
                                                                    type="text"
                                                                    value={auth.user.email}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-wrap">
                                                            <label>New Email Address</label>
                                                            <div className="input-outer">
                                                                <input
                                                                    className="common-inp alt"
                                                                    type="email"
                                                                    placeholder="Enter new email"
                                                                    value={email}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="alert alert-info my-3 text-center py-2" role="alert">
                                                    Updating your Email will log you out for re-login
                                                </div>
                                                <div className="text-end">
                                                    <button className="theme-btn site-link" type="submit"><span>{loader ? <>Updating  <FaSpinner className="spin" /></> : "Update"}</span></button>

                                                </div>


                                            </form>
                                        </div>
                                    </div>
                                    <div className="profileBlock-wrap profileBlock-wrap-last">
                                        <h2 className="infoTitle">Manage Your Acccount Data</h2>
                                        <div className="profileBlock mt-0">
                                            {/* <div className="manage-block">
                                                <div className="manage-block-left">
                                                    <p className="text-justify">AI Clients stores your private information like your name, email. It also stores your uploaded graphics such as images, videos and your music tiles along with TTS Text to speech. If you wish to download these data, you can do that -by clicking" download your data. </p>
                                                </div>
                                                <div className="manage-block-right">
                                                    <button className="btn btn-primary ml-2" onClick={handleDownload}>Download My Data</button>
                                                </div>
                                            </div> */}
                                            <div className="manage-block">
                                                <div className="manage-block-left">
                                                    <p className="text-justify">If you wish to stop your private data from getting processed on AI Clients  you can click the button to do so. Please note that doing this will disable your account and you will not be able to use AI Clients further. In order to reactivate your account, you would have to contact our support team at support@vineasx.com. Please <span>Proceed With Caution</span>.  </p>
                                                </div>
                                                <div className="manage-block-right">
                                                    <button className="btn btn-warnings ml-2" onClick={handleDisable}>Stop Processing My Data</button>
                                                </div>
                                            </div>
                                            <div className="manage-block">
                                                <div className="manage-block-left">
                                                    <p className="text-justify">If you wish to delete all your private data, you can do so by clicking this button. Please note that this will permanently delete all the data of yours from our server. This step is not reversible and you cannot use AI Clients further. You cannot undo this step from support also. So if you wish to re-use AI Clients again, then you will have to start all over again from scratch. Please <span>Proceed With Caution</span>.  </p>
                                                </div>
                                                <div className="manage-block-right">
                                                    <button className="btn btn-dangers ml-2" onClick={handleDelete}>Delete My Data</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            </Layout >
        </>
    )
}

export default PrivacySetting
