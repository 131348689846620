import React, { useState } from 'react'
import { Accordion, Button, Modal } from 'react-bootstrap'
import { BsFolder, BsPencil, BsTrash3 } from 'react-icons/bs'
import { LuEye } from "react-icons/lu";

import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux'
import { onDeleteFolder } from '../../Redux/Actions/AuthActions'
import WebsiteCard from './WebsiteCard';
import EditWebsiteModal from './EditWebsiteModal';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const WebsiteAccordion = ({ curElem, index, fetchLeadData, leads, setLeads }) => {
    const [show, setShow] = useState(false);
    const [checked, setChecked] = useState(true);
    const [selected, setSelected] = useState(null)
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChange = (e) => {
        setChecked(e.target.checked)
    }

    const [loader, setLoader] = useState(true)

    const handleDelete = async () => {
        let data = {
            id: curElem.sourceId
        }

        const result = await Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "You will not be able to recover this data!",
            showCancelButton: true,
            confirmButtonColor: "#d03e3e",
            cancelButtonColor: "#6c757d",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        });

        if (result.isConfirmed) {
            try {
                await dispatch(onDeleteFolder(data, fetchLeadData, false))

            } catch (error) {
                console.error('Error deleting items:', error);
            }
        }
    };

    // const handleDeleteById = () => {
    //     const filtered = curElem?.clients?.filter((item) => +item.id === +selected)
    //     console.log(filtered, "filtered")
    //     // dispatch(onDeleteLeads())
    // }
    return (
        <Accordion className='result_accordion'>
            <Accordion.Item eventKey={index}>
                <Accordion.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 ps-2 pe-2">
                        <div className='folder_Name'>
                            <BsFolder fontSize={17} className='me-2' />
                            {curElem.folderName}
                        </div>
                        <div className='folderLinlk'>
                            <Link to={`/website-email?sId=${curElem.sourceId}`}> <LuEye fontSize={17} /></Link>
                            {+auth.user.isClientAccount === 1 ? null :
                                <>
                                    <BsPencil onClick={handleShow} fontSize={17} />
                                    <BsTrash3 onClick={handleDelete} fontSize={17} />
                                </>
                            }

                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <table class="table widget-table">
                        <thead>
                            <tr>

                                <th scope="col">Email</th>
                                <th scope="col">Contact Details</th>
                                <th scope="col">Social Links</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {curElem?.website_email?.map((item, index) => {

                                return <WebsiteCard item={item} fetchLeadData={fetchLeadData} />
                            })}
                        </tbody>

                    </table>
                </Accordion.Body>
            </Accordion.Item>
            <EditWebsiteModal handleClose={handleClose} show={show} curElem={curElem} fetchLeadData={fetchLeadData} loader={loader} setLoader={setLoader} leads={leads} setLeads={setLeads} />

        </Accordion >)
}

export default WebsiteAccordion