import React, { useEffect, useMemo, useState } from "react";
import {

    BsShop,
    BsEnvelope,
    BsTelephone,
    BsGlobeAmericas,
    BsPeople,
    BsCameraVideo,
    BsCardImage,
    BsStarFill,
    BsStarHalf,
} from "react-icons/bs";
import { Autocomplete, LoadScript, useJsApiLoader, useLoadScript } from "@react-google-maps/api";
import { FaRegAddressCard, FaSpinner } from "react-icons/fa";
import {
    IoLocationOutline,
    IoTimeOutline,
} from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
// import "react-image-gallery/styles/css/image-gallery.css";
// import { themeColor } from "../../../../Global/Global";
import { BiSolidReport } from "react-icons/bi";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { onSearchGoogleData } from "../../Redux/Actions/AuthActions";
import { useDispatch } from "react-redux";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ReviewText from "./ReviewText";

const GoogleCard = ({ curElem, sId, currentTodo, setCurrentTodo, isLoaded }) => {
    const [googleData, setGoogleData] = useState([])
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [galleryImages, setGalleryImages] = useState([]);
    const [startIndexImg, setStartIndexImg] = useState(0);
    const [galleryShow, setGalleryShow] = useState(false);
    const [moreToggle, setMoreToggle] = useState({
        emails: 3,
        contacts: 3,
        videoLink: 3
    })
    const libraries = useMemo(() => ["places"], []);



    const handleGallery = () => {
        const images = googleData?.flatMap((item) =>
            item?.photos?.length > 0
                ? item.photos.map((url) => ({
                    original: url,
                    thumbnail: url,
                }))
                : []
        ) || [];

        setGalleryImages(images);
    };

    const handleGalleryShow = (index) => {
        setGalleryShow(true);
        setStartIndexImg(index);
    };

    const handleGalleryClose = () => {
        setGalleryShow(false);
    };

    const handleReadMore = (type) => {
        if (type === "emails") {
            setMoreToggle((preData) => {
                return {
                    ...moreToggle,
                    emails: preData.emails === 3 ? curElem?.emails?.length : 3
                }
            })
        } else if (type === "videoLink") {
            setMoreToggle((preData) => {
                return {
                    ...moreToggle,
                    videoLink: preData.videoLink === 3 ? curElem?.videoLink?.length : 3
                }
            })
        } else {
            setMoreToggle((preData) => {
                return {
                    ...moreToggle,
                    contacts: preData.contacts === 3 ? curElem?.contact?.length : 3
                }
            })
        }
    }
    const searchGoogleData = () => {
        let data = {

            id: curElem.id,
            url: curElem.mapUrl
        }

        dispatch(onSearchGoogleData(data, currentTodo, setCurrentTodo, setGoogleData))
    }

    const center = extractCoordinates(curElem.mapUrl)
    // function extractCoordinates(url) {
    //     const latMatch = url.match(/3d([\d.]+)!/);
    //     const lngMatch = url.match(/4d([\d.]+)!/);

    //     if (latMatch && lngMatch) {
    //         const lat = parseFloat(latMatch[1]);
    //         const lng = parseFloat(lngMatch[1]);
    //         return { lat, lng };
    //     } else {
    //         return { lat: 0, lng: 0 };
    //     }
    // }

    function extractCoordinates(url) {
        const latMatch = url.match(/!3d([\d.-]+)/);
        const lngMatch = url.match(/!4d([\d.-]+)/);

        if (latMatch && lngMatch) {
            const lat = parseFloat(latMatch[1]);
            const lng = parseFloat(lngMatch[1]);
            return { lat, lng };
        } else {
            return { lat: 0, lng: 0 };
        }
    }
    useEffect(() => {
        if (sId) {
            setGoogleData([{
                openingHours: curElem.openingHours,
                photos: curElem.photos,
                review: curElem.review,
            }])
            return
        }
        if (curElem.mapUrl) {
            searchGoogleData()

        }
    }, [])

    useEffect(() => {
        handleGallery();

    }, [curElem])
    return (
        <div className="col-lg-6 mb-4 mt-5">
            <div className="lead-single">
                <div className="lead-head">
                    <div className="lead-imgx">
                        <img src={curElem.icon} />
                        <p>{curElem.businessName}</p>
                    </div>

                    <div className="lead-ratings">
                        <div className="stars">
                            {+curElem?.rating?.length > 0
                                ? [...Array(Math.floor(+curElem.rating))].map((star, index) => (
                                    <BsStarFill key={index} />
                                ))
                                : ""}
                            {+curElem.rating % 1 !== 0 ? <BsStarHalf /> : ""}
                            star
                        </div>
                        <p className="mx-1">
                            ({+curElem.rating})

                        </p>
                    </div>
                </div>
                <div className="lead-img">
                    {isLoaded ?

                        <GoogleMap
                            mapContainerStyle={{ width: "100%", height: "400px" }}
                            center={center}
                            zoom={16}
                        >
                            {
                                <Marker
                                    position={extractCoordinates(curElem.mapUrl)}
                                />
                            }
                        </GoogleMap>
                        : "Loading..."}



                    <div className='map-holder'>
                        <iframe src={curElem.mapUrl}></iframe>
                    </div>
                </div>
                <div className="lead-details">
                    <ul>

                        <li>
                            <span>
                                <FaRegAddressCard /> Email
                            </span>
                            {curElem.loader ?
                                <FaSpinner className="spin ms-1" /> :
                                (googleData?.map((item) => {

                                    return (
                                        <span>{item.emails ? item.emails : "N/A"}</span>
                                    )
                                }))
                            }
                        </li>
                        <li>
                            <span>
                                <BsTelephone /> Phone
                            </span>
                            {curElem.loader ?
                                <FaSpinner className="spin ms-1" /> :

                                <span>{curElem.phoneNumber ? curElem.phoneNumber : "N/A"}</span>
                            }
                        </li>
                        <li>
                            <span>
                                <FaRegAddressCard /> Address
                            </span>
                            {curElem.loader ?
                                <FaSpinner className="spin ms-1" /> :
                                (googleData?.map((item) => {

                                    return (
                                        <span>{item.addressComponents ? item.addressComponents : "N/A"}</span>
                                    )
                                }))
                            }
                        </li>

                        <li>
                            <span>
                                <BsPeople /> Reviews
                            </span>
                            {curElem.loader ?
                                <FaSpinner className="spin ms-1" /> :
                                <span>{curElem.userRatingsTotal}</span>
                            }
                        </li>



                        <li>
                            <span>
                                <IoLocationOutline /> Landmark
                            </span>
                            <span>{curElem.landMark ? curElem.landMark : "N/A"}</span>
                        </li>
                        <li className="for-web">
                            <span>
                                <BsGlobeAmericas /> Website
                            </span>
                            {curElem.loader ?
                                <FaSpinner className="spin ms-1" />
                                :
                                <span>
                                    {curElem?.website ?
                                        <a className="linkBtn" target="_blank" href={curElem.website}>
                                            visit
                                        </a>
                                        :
                                        "N/A"
                                    }
                                </span>
                            }
                        </li>

                        <li className="align-items-start">
                            <span>
                                <IoTimeOutline /> Opening Hours
                            </span>

                            {curElem.loader ?
                                <FaSpinner className="spin ms-1" />

                                :

                                <span className="open-hrs">


                                    {googleData?.map((item, index) => (
                                        <div key={index} >
                                            {item?.openingHours.length > 0 ?
                                                (item?.openingHours?.map((i, subIndex) => (
                                                    <React.Fragment key={subIndex}>
                                                        {Object.entries(i).map(([day, hours]) => (
                                                            <div key={day} style={{ display: "flex" }}>
                                                                <span>{day}: </span>
                                                                <span>{hours}</span>
                                                            </div>
                                                        ))}
                                                    </React.Fragment>
                                                ))) : "N/A"}
                                        </div>
                                    ))}


                                </span>
                            }
                        </li>

                        <li>
                            <span>
                                <BsTelephone /> Contact No
                            </span>
                            {curElem.websiteLoader ? (
                                <FaSpinner className="spin ms-1" />

                            ) : (
                                <span className="open-hrs">
                                    {curElem?.contact && curElem.contact.length > 0 ?
                                        Array.isArray(curElem.contact) ?
                                            curElem.contact.map((number, index) => <div key={index}>{number}</div>).slice(0, moreToggle.contacts)
                                            :
                                            <div>{curElem.contact}</div>
                                        :
                                        "N/A"
                                    }
                                    {curElem?.contact && curElem.contact.length > 3 ?
                                        Array.isArray(curElem.contact) ?
                                            <span className='review_read_more'>
                                                <span style={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => handleReadMore("contacts")}>{moreToggle.contacts > 3 ? "ReadLess" : "ReadMore..."}</span>
                                            </span>
                                            : ""
                                        : ""
                                    }
                                </span>
                            )}
                        </li>



                        <li className="for-web"
                            style={{
                                alignItems: curElem.loader ? 'center' : 'start',
                            }}
                        >
                            <span>
                                <BsCardImage /> Images
                            </span>
                            <div className="web-images">
                                {curElem.loader ?
                                    <FaSpinner className="spin ms-1" /> :
                                    <>
                                        {googleData?.map((item, index) => (
                                            <div key={index} className="web_images_wrap" >
                                                {item?.photos?.length > 0 ?
                                                    item?.photos?.map((img, index) => (
                                                        <div className="web-images-single" key={index}>
                                                            <img
                                                                src={img}
                                                                alt=""
                                                                onClick={() => handleGalleryShow(index)}

                                                            />
                                                        </div>
                                                    )) : "N/A"}
                                            </div>
                                        ))}
                                    </>
                                }
                            </div>
                        </li>


                        <div>
                            <Modal
                                show={galleryShow}
                                className="theme-modal gallery-modal"
                                onHide={handleGalleryClose}
                            >
                                <Modal.Body>
                                    {/* <div
                                        className="modalClose text-end"
                                        onClick={handleGalleryClose}
                                    >
                                        <AiOutlineClose
                                            style={{ fontSize: "20px", marginBottom: "-55px" }}

                                        />
                                    </div> */}
                                    <ImageGallery
                                        items={galleryImages}
                                        startIndex={startIndexImg}
                                    />
                                </Modal.Body>
                            </Modal>
                        </div>


                        <li className="for-web">
                            <span>
                                <BsPeople fontSize={30} /> Recent Reviews
                            </span>
                            <div className="card-review">
                                {curElem.loader ?
                                    <FaSpinner className="spin ms-1" /> :

                                    googleData?.map((item, index) => (
                                        <div key={index}>
                                            {item?.review?.length > 0 ?
                                                item?.review?.map((review, index) => (
                                                    <React.Fragment key={index}>
                                                        <ReviewText review={review} />
                                                    </React.Fragment>
                                                )) : "N/A"}
                                        </div>
                                    ))}

                            </div>
                        </li>


                    </ul>
                </div>
            </div >

        </div >
    );
};

export default GoogleCard;
