import React from "react";

import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { CiSearch } from "react-icons/ci";
import dashboard from "../../images/1.png"
import dashboard2 from "../../images/2.png"
import dashboard3 from "../../images/3.png"
import dashboard4 from "../../images/4.png"
// import iconRight from "../../images/icon-arrow.svg"
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function Home() {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/sources")
  }
  return (
    <>
      <Header />
      <section className="hero-banner">
        <Helmet>
          <title> AI Clients | Dashboard</title>
        </Helmet>
        <div className="hero-video">
          {/* <iframe src="https://player.vimeo.com/video/428018128?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> */}
          {/* <iframe src="https://player.vimeo.com/video/955933322?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="app intro video"></iframe> */}

          {/* <iframe src="https://player.vimeo.com/video/955933322?badge=0&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="Final Sequence"></iframe> */}
          <video style={{ width: "100%" }} autoPlay muted loop>
            <source src="https://reeelapps-app.s3.us-west-2.amazonaws.com/AiClient/203987-923133879.mp4" />
          </video>
        </div>
        <div className="hero-banner-txt">
          <div className="container-fluid h-100">
            <div className="row align-items-center h-100">
              <div className="col-lg-6">
                <div className="hero-banner-left">
                  <h2>WELCOME TO</h2>
                  <h3>AI Clients</h3>
                  <p className="text-justify mt-3">
                    AI Clients empowers your online reputation management with powerful tools like our Geo Scraper, which collects essential data such as names, photos, reviews, locations, and ratings from various platforms. Plus, our Website Scraper gathers valuable insights from any site. Take charge of your digital presence and elevate your reputation in a competitive landscape.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hero-banner-right">

                  <a className="bannerLink" onClick={handleClick}>
                    <div className="bannerLink-left"><CiSearch fontSize={45} className="animate__animated animate__bounceIn animate__infinite custom-bounce me-2" />
                      Sources </div>
                    <div className="bannerLink-right"><MdKeyboardArrowRight fontSize={28} /></div>
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <section className="featureSec">
        <div className="container-fluid">
          <div className="featureSec-wrap">
            <div className="featureSec-single">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="featureSec-left">
                    <h6> Business Locator Insights</h6>
                    <p>
                      AI Client empowers you with a central hub for location intelligence. Access comprehensive insights on local businesses, including ratings, reviews, and key operational details. Utilize this data to refine your strategies, boost customer engagement, and drive growth. With real-time information at your fingertips, you can make informed decisions and stay ahead in the competitive landscape.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="featureSec-right"><img src={dashboard4} alt="" className="img-fluid" /></div>
                </div>
              </div>
            </div>
            <div className="featureSec-single">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="featureSec-left">
                    <h6>Full Automation</h6>
                    <p>Discover the power of total automation with AI Clients. Our system expertly handles routine tasks like monitoring reviews, updating profiles, and generating reports, freeing you to focus on strategic initiatives. Let AI Clients streamline your daily operations while you concentrate on driving your business forward.</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="featureSec-right"><img src={dashboard2} alt="" className="img-fluid" /></div>
                </div>
              </div>
            </div>
            <div className="featureSec-single">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="featureSec-left">
                    <h6>Geo Scraper</h6>
                    <p>
                      Unlock the power of location-based data with Geo Scraper. Our advanced tool allows you to effortlessly extract vital information from various geographic sources, including business details, reviews, and ratings. Streamline your data collection process to enhance your market analysis and drive strategic decisions. Stay ahead of the competition with real-time insights tailored to your business needs.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="featureSec-right"><img src={dashboard3} alt="" className="img-fluid" /></div>
                </div>
              </div>
            </div>
            <div className="featureSec-single">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="featureSec-left">
                    <h6>Simplified Lead Generation</h6>
                    <p>
                      Revolutionize how you find and manage leads with AI Clients. Our advanced search and filtering capabilities enable you to identify your ideal clients with precision. Simplify your lead generation process, focus on the right audience, and transform prospects into loyal customers more efficiently.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="featureSec-right"><img src={dashboard} alt="" className="img-fluid" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      <Footer />
    </>
  )
}

export default Home;