import React, { useEffect } from 'react'

const WesiteScrappedData = ({ item }) => {
    const contactNumbers = item.contactNumber ? item.contactNumber.split(',') : ["N/A"];
    const email = item.email ? item.email.split(',') : ["N/A"];

    return (
        <>

            <div className="website_data_wrap">
                <div className="row">
                    <div className="col-xl-6 col-12 mb-4">
                        <div className="inner_data_wrap">
                            <div className="row">
                                <div className="col-md-3 col-sm-4 col-12 data_end">
                                    <h6>Email:</h6>
                                </div>
                                <div className="col-md-9 col-sm-8 col-12">
                                    <div className='response_data'>
                                        <div className="row">
                                            {email.length > 0 ? email.map((number, index) => (
                                                <div key={index}>{number.trim().replace(/\s+/g, '')}
                                                    {index < email.length - 1 && ','}
                                                </div>
                                            )) : "N/A"}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 mb-4">
                        <div className="inner_data_wrap">
                            <div className="row">
                                <div className="col-md-3 col-sm-4 col-12 data_end">
                                    <h6>Contact:</h6>
                                </div>
                                <div className="col-md-9 col-sm-8 col-12">
                                    <div className='response_data'>
                                        <div className="row">
                                            {contactNumbers.length > 0 ? contactNumbers.map((number, index) => (
                                                <div key={index}>{number.trim().replace(/\s+/g, '')}
                                                    {index < contactNumbers.length - 1 && ','}
                                                </div>
                                            )) : "N/A"}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-6 mb-4">
                        <div className="inner_data_wrap">
                            <div className="row">
                                <div className="col-12 ">
                                    <h5 style={{ padding: "0 0 24px 36px" }}>All Script </h5>
                                </div>

                                <div className="col-12">
                                    <ul>
                                        <li className='mb-3'>
                                            <h6>GoogleAds Pixel :-</h6><span>{item.googleAdsPixel === "0" ? "false" : item.googleAdsPixel === "1" ? "true" : "N/A"}</span>
                                        </li>
                                        <li className='mb-3'>
                                            <h6>Google Analytics :-</h6><span>{item.googleAnalytics === "0" ? "false" : item.googleAnalytics === "1" ? "true" : "N/A"}</span>
                                        </li>
                                        <li className='mb-3'>
                                            <h6>Facebook Analytics Pixel :-</h6><span>{item.facebookAnalyticsPixel === "0" ? "false" : item.facebookAnalyticsPixel === "1" ? "true" : "N/A"}</span>
                                        </li>

                                        <li className='mb-3'>
                                            <h6>Google Tag Manager :-</h6><span>{item.googleTagManager === "0" ? "false" : item.googleTagManager === "1" ? "true" : "N/A"}</span>
                                        </li>
                                        <li className='mb-3'>
                                            <h6>Facebook Pixel :-</h6><span>{item.facebookPixel === "0" ? "false" : item.facebookPixel === "1" ? "true" : "N/A"}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mb-4">
                        <div className="inner_data_wrap">
                            <div className="row">
                                <div className="col-12 ">
                                    <h5 style={{ padding: "0 0 24px 36px" }}>Extension: </h5>
                                </div>

                                <div className="col-12">
                                    <ul>
                                        <li className='mb-3'>
                                            <h6>Contact Call To Action :-</h6><span>{item.contactCallToAction === "0" ? "false" : item.contactCallToAction === "1" ? "true" : "N/A"}</span>
                                        </li>
                                        <li className='mb-3'>
                                            <h6>Webmaster Tools :-</h6><span>{item.webmasterTools === "0" ? "false" : item.webmasterTools === "1" ? "true" : "N/A"}</span>
                                        </li>
                                    </ul>
                                </div>

                                {/* <div className="col-12 col-md-6 mb-md-0 mb-4">
                  <div className="row">
                    <div className="col-xl-6 col-6 data_end">
                      <h6>Contact Call To Action :-</h6>
                    </div>

                    <div className="col-xl-6 col-6">
                      <div className='response_data'>
                        <div className="row">
                          <div className="col-12 ">
                            <span>{item.additionalInfo.extension.contactCallToAction?.toString()}</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-md-0 mb-4">
                  <div className="row">
                    <div className="col-xl-6 col-6 data_end">
                      <h6>Webmaster Tools :-</h6>
                    </div>

                    <div className="col-xl-6 col-6">
                      <div className='response_data'>
                        <div className="row">
                          <div className="col-12 ">
                            <span>{item.additionalInfo.extension.webmasterTools?.toString()}</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div> */}


                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-12 mb-4">
                        <div className="inner_data_wrap">
                            <div className="row">
                                <div className="col-12 ">
                                    <h5 style={{ padding: "0 0 24px 36px" }}>Social Links </h5>

                                </div>

                                <div className="col-xl-6 col-12 mb-md-0 mb-4">
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-2 col-sm-3 col-12 data_end">
                                            <h6>Facebook:</h6>
                                        </div>

                                        <div className="col-xl-9 col-lg-10 col-sm-9 col-12">
                                            <div className='response_data'>
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        {item.facebook ? item.facebook : "N/A"}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-12 mb-md-0 mb-4">
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-2 col-sm-3 col-12 data_end">
                                            <h6>Instagram:</h6>
                                        </div>

                                        <div className="col-xl-9 col-lg-10 col-sm-9 col-12">
                                            <div className='response_data'>
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        {item.instagram ? item.instagram : "N/A"}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-12 mb-md-0 mb-4">
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-2 col-sm-3 col-12 data_end">
                                            <h6>Pinterest:</h6>
                                        </div>

                                        <div className="col-xl-9 col-lg-10 col-sm-9 col-12">
                                            <div className='response_data'>
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        {item.pinterest ? item.pinterest : "N/A"}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-12 mb-md-0 mb-4">
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-2 col-sm-3 col-12 data_end">
                                            <h6>Whatsapp:</h6>
                                        </div>

                                        <div className="col-xl-9 col-lg-10 col-sm-9 col-12">
                                            <div className='response_data'>
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        {item.whatsapp ? item.whatsapp : "N/A"}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-12 mb-md-0 mb-4">
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-2 col-sm-3 col-12 data_end">
                                            <h6>Youtube:</h6>
                                        </div>

                                        <div className="col-xl-9 col-lg-10 col-sm-9 col-12">
                                            <div className='response_data'>
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        {item.youtube ? item.youtube : "N/A"}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-12 mb-md-0 mb-4">
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-2 col-sm-3 col-12 data_end">
                                            <h6>LinkedIn:</h6>
                                        </div>

                                        <div className="col-xl-9 col-lg-10 col-sm-9 col-12">
                                            <div className='response_data'>
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        {item.linkedin ? item.linkedin : "N/A"}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


        </>
    )
}

export default WesiteScrappedData