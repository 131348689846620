import React, { useEffect, useState } from 'react';
import ResultsRowCard from './ResultsRowCard';
import { Accordion, Button, Modal } from 'react-bootstrap';
import { BsFolder, BsPencil, BsTrash3 } from 'react-icons/bs';
import Header from '../Common/Header';

import { useDispatch, useSelector } from 'react-redux';
import { onFetchLeads } from '../../Redux/Actions/AuthActions';
import ResultModal from './ResultModal';
import ResultAccordian from './ResultAccordian';
import { FaSpinner } from 'react-icons/fa';
import Footer from '../Common/Footer';
import Layout from '../../Layout';
import { Helmet } from 'react-helmet';

const ResultsCard = () => {
    const [leads, setLeads] = useState([]);
    const [loader, setLoader] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const dispatch = useDispatch();
    const fetchLeadData = () => {
        dispatch(onFetchLeads(setLeads, setLoader));
    };
    useEffect(() => {
        fetchLeadData();
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = leads.sources?.slice(indexOfFirstItem, indexOfLastItem);

    const totalItems = leads.sources?.length || 0;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        <>
            <Layout>
                <Helmet>
                    <title> AI Clients | Results</title>
                </Helmet>
                <div className="page-container">
                    <div className="container">
                        <div className="row">
                            {loader ?
                                <div style={{ textAlign: "center", fontSize: "30px" }}>
                                    <FaSpinner className="spin ms-1" /></div> :
                                <div className="col-12">
                                    {currentItems?.length > 0 ?
                                        currentItems?.map((curElem, index) => (
                                            <React.Fragment key={index}>
                                                <ResultAccordian
                                                    curElem={curElem}
                                                    index={index}
                                                    fetchLeadData={fetchLeadData}
                                                    loader={loader}
                                                    leads={leads}
                                                    setLeads={setLeads}
                                                    setLoader={setLoader}
                                                />
                                            </React.Fragment>
                                        ))
                                        : <div style={{ textAlign: "center", fontSize: "30px" }}>No Data Found</div>}

                                    {totalItems > 10 && currentItems?.length > 0 ? <div className="result-page-controls pagination-controls mb-4">
                                        <Button
                                            disabled={currentPage === 1}
                                            onClick={() => handlePageChange(currentPage - 1)}
                                        >
                                            Previous
                                        </Button>

                                        <Button
                                            disabled={currentPage === totalPages}
                                            onClick={() => handlePageChange(currentPage + 1)}
                                        >
                                            Next
                                        </Button>
                                    </div> : ""}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default ResultsCard;
