import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { masterLoginUser } from '../../Redux/Actions/AuthActions'
import { useDispatch, useSelector } from 'react-redux'
import { FaSpinner } from 'react-icons/fa'

function MasterLogin() {
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const navigate = useNavigate()
    const [login, setLogin] = useState({
        useremail: '',
        email: "",
        password: ""
    })
    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate('/')
        }
    }, [auth])

    const handleChange = (e) => {
        const { name, value } = e.target
        setLogin({
            ...login, [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(masterLoginUser(login, setLoader, navigate))
    }

    return (
        <>
            <div className="login-header">
                <div className="container">
                    <div className="row">
                        <div className="logo"><img src={require("../../images/logo.svg").default} alt="" /></div>
                    </div>
                </div>
            </div>
            <div className='login-container'>
                <div className='container'>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5">
                            <div className="login-left">
                                <h3>Welcome to AI Clients</h3>
                                <p className='pt-3'>Elevate your digital presence with our AI-driven tool for effortless reputation management. Collect and analyze key insights from various sources—business details, visual content, customer feedback, and ratings. Start your journey towards a powerful online identity now.
                                </p>
                                <h3 className='pt-4' style={{ fontSize: "20px" }}>..it's Super Easy-to-use</h3>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <form onSubmit={handleSubmit}>
                                <div className="login-right">
                                    <div className='login-card'>
                                        <h5 className='mb-4'>Master Login to AI Clients</h5>
                                        <div className="input-wrap">
                                            <label className='label' htmlFor="">User Email Address:</label>
                                            <input className='input' type="email" placeholder='Email User Email' onChange={handleChange} value={login.useremail} name="useremail" required />
                                        </div>
                                        <div className="input-wrap mt-4">
                                            <label className='label' htmlFor="">Admin Email Address:</label>
                                            <input className='input' type="email" placeholder='Email' onChange={handleChange} value={login.email} name="email" required />
                                        </div>
                                        <div className="input-wrap mt-4">
                                            <label className='label' htmlFor="" >Password:</label>
                                            <input className='input' type="password" placeholder='Enter Your Password' onChange={handleChange} value={login.password} name="password" required />
                                        </div>
                                        <div className="input-wrap mt-4">
                                            <button className='site-link lg full black mt-1'>Login  {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                                        </div>
                                        <p className='text-center pt-2'>Forgot your account details? <Link className='txt-orange' to="/forgot-password">Click here</Link></p>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}

export default MasterLogin