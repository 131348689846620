import React from 'react'
import { BsGlobe } from 'react-icons/bs'
import { CiMail } from 'react-icons/ci'
import { FiPhone } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import { onDeleteLeads, onDeleteWebsite } from '../../Redux/Actions/AuthActions'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
const WebsiteCard = ({ item, fetchLeadData }) => {
    const contactNumbers = item.contactNumber ? item.contactNumber.split(',') : ["N/A"];
    const email = item.email ? item.email.split(',') : ["N/A"];
    const dispatch = useDispatch()
    const handleDeleteById = async () => {

        const result = await Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "You will not be able to recover this data!",
            showCancelButton: true,
            confirmButtonColor: "#d03e3e",
            cancelButtonColor: "#6c757d",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        });
        let data = {
            id: item.id
        }
        if (result.isConfirmed) {
            try {
                await dispatch(onDeleteWebsite(data, fetchLeadData, false));
            } catch (error) {
                console.error('Error deleting items:', error);
            }
        }
    };

    return (
        // <tbody>
        <tr>
            {/* <div>
                // const contactNumbers = item.contactNumber ? item.contactNumber.split(',').map(num => num.trim()) : ["N/A"];
                const contactNumbers = item.contactNumber ? item.contactNumber.split(',').map(num => num.replace(/"/g, '').trim()) : ["N/A"];
                    {contactNumbers.map((number, index) => (
                        <div key={index}>
                            {number}
                            {index < contactNumbers.length - 1 && ','}
                        </div>
                    ))}
                </div> */}
            {/* <td>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="checked" />
                    </div>
                </td> */}
            <td className=''>
                {email.length > 0 ? email.map((number, index) => (
                    <div key={index}>{number.trim().replace(/\s+/g, '')}
                        {index < email.length - 1 && ','}
                    </div>
                )) : "N/A"}
            </td>
            <td>
                {contactNumbers.map((number, index) => (
                    <div key={index}>{number.trim().replace(/\s+/g, '')}
                        {index < contactNumbers.length - 1 && ','}
                    </div>
                ))}

            </td>
            <td>

                {item.facebook ? item.facebook : "N/A"}<br />
                {item.instagram ? item.instagram : "N/A"}<br />
                {item.linkedin ? item.linkedin : "N/A"}<br />
                {item.pinterest ? item.pinterest : "N/A"}<br />
                {item.twitter ? item.twitter : "N/A"}<br />
                {item.youtube ? item.youtube : "N/A"}<br />
                {item.whatsapp ? item.whatsapp : "N/A"}<br />

            </td>
            {/* <td>
                {item.facebookAnalyticsPixel ? item.facebookAnalyticsPixel : "N/A"}<br />


            </td>
            <td>
                {item.facebookAnalyticsPixel ? item.facebookAnalyticsPixel : "N/A"}<br />


            </td> */}
            <td>
                <MdDelete fontSize={20} onClick={handleDeleteById} style={{ cursor: "pointer" }} />
            </td>

        </tr>

        // </tbody>
    )
}

export default WebsiteCard