

import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { linkedinProfile, onFetchWebsiteData, websiteScrapper } from '../../Redux/Actions/AuthActions'
import { FaSpinner } from 'react-icons/fa'
import Layout from '../../Layout'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import WebsiteEmailCard from './WebsiteEmailCard'
import WesiteScrappedData from './WesiteScrappedData'

const WebsiteEmail = () => {
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation();

  const [error, setError] = useState('');
  const [webData, setWebData] = useState("")
  const [state, setState] = useState({
    url: ""
  })
  const { sId } = queryString.parse(location.search);
  const [websiteScrap, setWebsiteScrap] = useState([])
  const validateURL = (url) => {
    const urlPattern = /^(https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*)$/i;
    return urlPattern.test(url);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value })
  }
  const handleSearch = (e) => {
    e.preventDefault()
    if (!validateURL(state.url)) {
      setError('Please enter a valid URL.');
      return;
    }
    let data = {
      website_email: state.url
    }
    setError('');
    setLoader(true)
    dispatch(linkedinProfile('website-email', data, setWebData, setLoader))
  }
  const websiteData = () => {

    let datum = {
      id: webData
    }
    setLoader(true)
    dispatch(websiteScrapper(datum, setWebsiteScrap, setLoader))
  }
  const emailData = () => {
    setLoader(true)
    dispatch(onFetchWebsiteData(sId, setWebsiteScrap, setLoader))
  }
  useEffect(() => {
    if (webData) {
      websiteData()

    }
  }, [webData])
  useEffect(() => {

    if (sId) {
      emailData()
    }
  }, [sId])
  return (
    <Layout>
      <div className="page-container">
        <div className="container">
          {sId ? "" :
            <>
              <div className='data-page'>
                <form onSubmit={handleSearch}>
                  <h4 className='page-title'><img src={require("../../images/icon/dribble.svg").default} alt="" />Website Email Search Data</h4>
                  <div className="input-wrap mt-5">
                    <label className='label' htmlFor="">Enter Website URL:</label>
                    <textarea name="url" className='input' id="" placeholder='Enter Website URLs (comma separated, multiple allowed, duplicates will be removed)' onChange={handleChange} value={state.url} required ></textarea>
                    {error && <p className='error-text'>{error}</p>}

                  </div>
                  {/* <h6 className='pt-4'>Scraping <span className='txt-orange'>22</span> URLs  for <span className='txt-orange'>100</span> credits.</h6> */}

                  <div className='text-center'><button className='site-link lg dark-blue mt-5'>Start Search {loader ? <FaSpinner className="spin ms-1" /> : null}</button></div>
                </form>
                {/* <SearchResults /> */}
                {/* {
              websiteScrap.map((item, index) => {
                return (
                  <div key={index} style={{ fontSize: "15px", marginTop: "20px" }}>
                    {item.emails.length > 0 ? item.emails : <div style={{ textAlign: "center", fontSize: "20px", marginTop: "20px" }}>No result found</div>}
                  </div>
                );
              })
            } */}



              </div>
              <WebsiteEmailCard websiteScrap={websiteScrap} sId={sId} />
            </>

          }
          {sId ? <>
            {loader ?
              <div style={{ fontSize: "30px", textAlign: "center" }} >
                <FaSpinner className="spin ms-1" />

              </div> :
              <>

                <div className="col-12">
                  <h3 className='my-3 ms-1'>Website Search Data</h3>
                  {websiteScrap?.map((item) => {
                    return <div className="row">
                      <WesiteScrappedData websiteScrap={websiteScrap} sId={sId} item={item} />
                    </div>
                  })}
                </div>
              </>




            }
          </> : ""}

        </div>
      </div>

    </Layout >
  )

}

export default WebsiteEmail